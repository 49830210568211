<template>
  <el-container>
    <el-main>
      <div class="login_container">
        <h1>代收费后台管理</h1>
        <div class="login_box">
          <!-- 头像区域 -->
          <div class="avatar_box">
            <img src="../assets/logo.png" alt="" />
          </div>

          <el-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            class="login_form"
            label-width="0px"
          >
            <!-- 用户名 -->
            <el-form-item prop="username">
              <el-input
                v-model="loginForm.username"
                prefix-icon="iconfont icon-user"
              ></el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                prefix-icon="iconfont icon-3702mima"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item class="btns">
              <el-button :disabled="loading" type="primary" @click="login"
                >登录</el-button
              >
              <el-button :disabled="loading" type="info" @click="resetLoginForm"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div></el-main
    >
  </el-container>
</template>

<script>
import { request } from "../network/request";

export default {
  data() {
    return {
      loginForm: {
        username: "admin",
        password: "123456",
      },
      loginFormRules: {
        //   验证用户名是否合法
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        //   验证密码是否合法
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    //点击按钮 重置登录表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      let self = this;
      this.loading = true;
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;

        self
          .rq({
            url: "login",
            params: {
              name: self.loginForm.username,
              password: self.loginForm.password,
            },
          })
          .then((res) => {
            //this.context = res;
            self.loading = false;
            if (res) {
              if (res.data == 1) {
                this.$message.success("登录成功");
                // 登录成功之后的token，保存到客户端的sessionStrage
                window.sessionStorage.setItem("token", self.loginForm.username);
                // 通过编程式导航跳转到后台主页 路由地址是 /home
                this.$router.push("/admin");
              } else {
                this.$message.error("登录失败，账号密码错误");
              }
            }
          })
          .catch((err) => {
            //this.context = err;
            self.msg = "登录异常";
            self.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  height: 100%;
  h1 {
    display: flex;
    justify-content: center;
  }
  .login_box {
    width: 450px;
    height: 300px;
    background-color: #fff;
    border-radius: 3px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .avatar_box {
      height: 130px;
      width: 130px;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      box-shadow: 0 0 10px #ddd;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #eee;
      }
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>